import React from "react"
import GlobalStateProvider from "./src/store/GlobalStateProvider"
require("typeface-open-sans")
require("typeface-pt-serif")
require("./src/styles/tailwind.css")
require("yet-another-react-lightbox/styles.css")
require("yet-another-react-lightbox/plugins/thumbnails.css")
require("yet-another-react-lightbox/plugins/counter.css")
require("react-photo-album/styles.css")

export const wrapRootElement = ({ element }) => (
  <GlobalStateProvider>{element}</GlobalStateProvider>
)

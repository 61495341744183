// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-anfrage-gesendet-jsx": () => import("./../../../src/pages/anfrage-gesendet.jsx" /* webpackChunkName: "component---src-pages-anfrage-gesendet-jsx" */),
  "component---src-pages-datenschutz-jsx": () => import("./../../../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-fotoreisen-fotoexkursionen-fotoreisen-title-slug-jsx": () => import("./../../../src/pages/fotoreisen-fotoexkursionen/{fotoreisen.title_slug}.jsx" /* webpackChunkName: "component---src-pages-fotoreisen-fotoexkursionen-fotoreisen-title-slug-jsx" */),
  "component---src-pages-fotoreisen-fotoexkursionen-jsx": () => import("./../../../src/pages/fotoreisen-fotoexkursionen.jsx" /* webpackChunkName: "component---src-pages-fotoreisen-fotoexkursionen-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-teilnahmebedingungen-jsx": () => import("./../../../src/pages/teilnahmebedingungen.jsx" /* webpackChunkName: "component---src-pages-teilnahmebedingungen-jsx" */),
  "component---src-pages-ueber-uns-jsx": () => import("./../../../src/pages/ueber-uns.jsx" /* webpackChunkName: "component---src-pages-ueber-uns-jsx" */),
  "component---src-pages-verlag-buecher-jsx": () => import("./../../../src/pages/verlag/buecher.jsx" /* webpackChunkName: "component---src-pages-verlag-buecher-jsx" */),
  "component---src-pages-verlag-jsx": () => import("./../../../src/pages/verlag.jsx" /* webpackChunkName: "component---src-pages-verlag-jsx" */),
  "component---src-pages-verlag-kalender-jsx": () => import("./../../../src/pages/verlag/kalender.jsx" /* webpackChunkName: "component---src-pages-verlag-kalender-jsx" */),
  "component---src-pages-verlag-sonderausgaben-jsx": () => import("./../../../src/pages/verlag/sonderausgaben.jsx" /* webpackChunkName: "component---src-pages-verlag-sonderausgaben-jsx" */),
  "component---src-pages-verlag-spezial-jsx": () => import("./../../../src/pages/verlag/spezial.jsx" /* webpackChunkName: "component---src-pages-verlag-spezial-jsx" */),
  "component---src-pages-verlag-zeitschriften-einzelheftbestellung-jsx": () => import("./../../../src/pages/verlag/zeitschriften/einzelheftbestellung.jsx" /* webpackChunkName: "component---src-pages-verlag-zeitschriften-einzelheftbestellung-jsx" */),
  "component---src-pages-verlag-zeitschriften-jsx": () => import("./../../../src/pages/verlag/zeitschriften.jsx" /* webpackChunkName: "component---src-pages-verlag-zeitschriften-jsx" */),
  "component---src-templates-publikationen-jsx": () => import("./../../../src/templates/publikationen.jsx" /* webpackChunkName: "component---src-templates-publikationen-jsx" */)
}


import { useReducer } from "react"
import storage from "local-storage-fallback"

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_HIDE_BANNER":
      storage.setItem("hideBanner", action.payload)
      return {
        hideBanner: action.payload,
      }
    default: {
      return state
    }
  }
}

const useGlobalState = () => {
  const [state, dispatch] = useReducer(reducer, {
    hideBanner: storage.getItem("hideBanner") ? true : false,
  })

  return { state, dispatch }
}

export default useGlobalState
